import React from "react";
import Utils, {initial_toast, Loading, MyDateInput, MyInput, MySelect, Toaster} from "../../utils/Utils";
import PhoneInput from "react-phone-input-2";
import {Button} from "semantic-ui-react";
import {Request} from "../../utils/Request";
import {countries} from "../../utils/Countries";
import moment from "moment";

export interface ClientInfo {
    client_id: number
    account_id: number
    is_child: number

    first_name: string,
    last_name: string,
    other_name: string,
    client_gender: string,
    date_of_birth: string

    mobile_contact: string
    email_address: string
    passport_no: string
    group_id: number

    nationality: string
    village: string
    sub_county: string
    parish: string
    district: string

    next_of_kin: string
    next_of_kin_contact: string,
}

export const initial_client: ClientInfo = {
    client_gender: "", date_of_birth: "", district: "", email_address: "", first_name: "", last_name: "",
    mobile_contact: "", nationality: "", next_of_kin: "", next_of_kin_contact: "", group_id: 0,
    other_name: "", parish: "", sub_county: "", village: "", passport_no: "", client_id: 0, is_child: 0, account_id: 0
}

export default function ClientAccount(props: { client_info: ClientInfo, setClientInfo: (client_info: ClientInfo) => void }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [toast, setToast] = React.useState(initial_toast)

    const [saving, setSaving] = React.useState(false)
    const [clientInfo, setClientInfo] = React.useState(initial_client)
    const handle_client = (name: string, value: string | number) => {
        setClientInfo({...clientInfo, [name]: value})
    }

    const is_valid_data = () => {
        if (Utils.invalid_name(clientInfo.first_name.trim())) {
            setToast({message: "Enter a valid first name", time: Date.now(), type: 'error'})
        } else if (Utils.invalid_name(clientInfo.last_name.trim())) {
            setToast({message: "Enter a valid surname", time: Date.now(), type: 'error'})
        } else if (clientInfo.other_name.trim().length > 0 && Utils.invalid_name(clientInfo.other_name.trim())) {
            setToast({message: "Enter a valid other name", time: Date.now(), type: 'error'})
        } else if (clientInfo.nationality === '') {
            setToast({message: "Please select nationality", time: Date.now(), type: 'error'})
        } else if (clientInfo.date_of_birth === "") {
            setToast({message: "Enter a valid date of birth", time: Date.now(), type: 'error'})
        } else if (moment(clientInfo.date_of_birth).isBefore(moment("1900-01-01"))) {
            setToast({message: "Date of birth cannot be before 01 January 1900", time: Date.now(), type: 'error'})
        } else if (moment(clientInfo.date_of_birth).isAfter(moment(Utils.today()))) {
            setToast({message: "Date of birth cannot be after today", time: Date.now(), type: 'error'})
        } else if (clientInfo.client_gender === "") {
            setToast({message: "Please select a gender", time: Date.now(), type: 'error'})
        } else if (Utils.invalid_contact(clientInfo.mobile_contact)) {
            setToast({message: "Enter a valid mobile contact", time: Date.now(), type: 'error'})
        } else if (Utils.in_valid_email(clientInfo.email_address.trim())) {
            setToast({message: "Enter a valid email address", time: Date.now(), type: 'error'})
        } else if (clientInfo.district.trim().length < 3) {
            setToast({message: "Enter your residential district", time: Date.now(), type: 'error'})
        } else if (clientInfo.village.trim().length < 3) {
            setToast({message: "Enter your residential village", time: Date.now(), type: 'error'})
        } else if (clientInfo.next_of_kin.trim().length < 5) {
            setToast({message: "Enter a valid next of kin name", time: Date.now(), type: 'error'})
        } else if (Utils.invalid_contact(clientInfo.next_of_kin_contact)) {
            setToast({message: "Enter a valid next of kin mobile contact", time: Date.now(), type: 'error'})
        } else {
            return true
        }
        return false
    }

    const update_data = () => {
        if (is_valid_data()) {
            setSaving(true)
            Request.update_account({data: JSON.stringify(clientInfo)})
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            setToast({message: "Successfully updated user profile", time: Date.now(), type: 'success'})
                            setClientInfo({
                                ...clientInfo, client_id: response.data.data.client_id, account_id: response.data.data.account_id
                            })
                            props.setClientInfo({
                                ...clientInfo, client_id: response.data.data.client_id, account_id: response.data.data.account_id
                            })
                        } else if (response.data.code === 2) {
                            setToast({
                                message: `Email address is already in use by <br/>"<b>${response.data.data.user_name}</b>"` +
                                    `Make sure that the data is entered correctly or add as child under` +
                                    `<br/>"<b>${response.data.data.user_name}</b>" if they share the same email address`,
                                time: Date.now(), type: 'error'
                            })
                        } else if (response.data.code === 3) {
                            setToast({
                                message: `Mobile contact is already in use by <br/>"<b>${response.data.data.user_name}</b>"` +
                                    `Make sure that the data is entered correctly or add as child under` +
                                    `<br/>"<b>${response.data.data.user_name}</b>" if they share the same mobile contact`,
                                time: Date.now(), type: 'error'
                            })
                        } else if (response.data.code === 4) {
                            setToast({message: "Client data could not be matched, please retry", time: Date.now(), type: 'error'})
                        } else if (response.data.code === 2) {
                            setToast({message: "Could not process data", time: Date.now(), type: 'error'})
                        }
                    } else {
                        setToast({message: "Error while saving client data, please retry", time: Date.now(), type: 'error'})
                    }
                })
                .catch(() => {
                    setSaving(false)
                    setToast({message: "Error while saving client data, please retry", time: Date.now(), type: 'error'})
                })
        }
    }

    React.useEffect(() => {
        let _client_info = {...props.client_info}
        if (_client_info.mobile_contact !== "") {
            _client_info = {..._client_info, mobile_contact: "+" + _client_info.mobile_contact}
        }
        if (_client_info.next_of_kin_contact !== "") {
            _client_info = {..._client_info, next_of_kin_contact: "+" + _client_info.next_of_kin_contact}
        }
        setClientInfo(_client_info)
    }, [props.client_info])

    return <>
        <Toaster type={toast.type} message={toast.message} time={toast.time}/>

        <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

        <div className="container-fluid m-0 p-0 h-100">
            <div className="row m-0 user_info_form">
                <div className="col-12 col-md-8 offset-md-2 p-1">
                    <div className="test_header">Personal Information</div>
                    <div className="user_info">
                        <div className="row m-0">

                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">First Name</div>
                                    <MyInput placeholder="First Name" name="first_name"
                                             value={clientInfo.first_name} change={handle_client}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">Surname</div>
                                    <MyInput placeholder="Enter surname" name="last_name"
                                             value={clientInfo.last_name} change={handle_client}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">Other Name (optional)</div>
                                    <MyInput placeholder="Other Name" name="other_name"
                                             value={clientInfo.other_name} change={handle_client}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">Nationality</div>
                                    <MySelect
                                        change={(value) => handle_client("nationality", value as string)}
                                        name="nationality" value={clientInfo.nationality} placeholder="Select nationality"
                                        options={countries}/>
                                </div>
                            </div>
                        </div>


                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">Date of Birth</div>
                                    <MyDateInput
                                        value={clientInfo.date_of_birth} name="date_of_birth"
                                        maxDate={Utils.today()} minDate="1900-01-01"
                                        placeholder="Select date of birth" change={handle_client}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">Gender</div>
                                    <MySelect
                                        change={(value) => handle_client("client_gender", value as string)}
                                        name="client_gender" value={clientInfo.client_gender} placeholder="Select user gender"
                                        options={[
                                            {value: "Other", text: "Other"},
                                            {value: "Male", text: "Male"},
                                            {value: "Female", text: "Female"},
                                        ]}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="mb-md-0 user_data_container">
                                    <div className="label">Passport No. (necessary for travellers)</div>
                                    <MyInput placeholder="Passport No" name="passport_no"
                                             value={clientInfo.passport_no} change={handle_client}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="test_header">Address and Contact Info</div>
                    <div className="user_info">
                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">Email Address</div>
                                    <MyInput placeholder="Email Address" name="email_address"
                                             disabled={true} value={clientInfo.email_address} change={handle_client}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="label">Mobile Contact</div>
                                <PhoneInput
                                    disabled={clientInfo.is_child === 1}
                                    countryCodeEditable={true} country={'ug'} value={clientInfo.mobile_contact}
                                    onChange={(value) => handle_client('mobile_contact', value)}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">Residential Sub Country (optional)</div>
                                    <MyInput placeholder="Enter sub county" name="sub_county" change={handle_client}
                                             disabled={clientInfo.is_child === 1} value={clientInfo.sub_county}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="user_data_container">
                                    <div className="label">Residential District (In Uganda)</div>
                                    <MyInput placeholder="Enter district" name="district" change={handle_client}
                                             disabled={clientInfo.is_child === 1} value={clientInfo.district}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="mb-md-0 user_data_container">
                                    <div className="label">Residential Parish (optional)</div>
                                    <MyInput placeholder="Enter parish" name="parish" value={clientInfo.parish}
                                             disabled={clientInfo.is_child === 1} change={handle_client}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="mb-0 user_data_container">
                                    <div className="label">Residential Village (In Uganda)</div>
                                    <MyInput placeholder="Enter village" name="village" value={clientInfo.village}
                                             disabled={clientInfo.is_child === 1} change={handle_client}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="test_header">Next Of Kin</div>
                    <div className="user_info">
                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-1">
                                <div className="mb-md-0 user_data_container">
                                    <div className="label">Next of kin full name</div>
                                    <MyInput placeholder="Next of kin name" name="next_of_kin" change={handle_client}
                                             value={clientInfo.next_of_kin} disabled={clientInfo.is_child === 1}/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="label">Mobile Contact</div>
                                <PhoneInput
                                    disabled={clientInfo.is_child === 1} country={'ug'}
                                    value={clientInfo.next_of_kin_contact} containerStyle={{marginBottom: '0px'}}
                                    onChange={(value) => {
                                        handle_client('next_of_kin_contact', value)
                                    }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row m-0 user_info_button">
                <div className="col-12 col-md-4 offset-md-4 p-1">
                    <Button primary icon="save" labelPosition="left" size="tiny" fluid
                            content="Update Information" onClick={update_data} disabled={saving} loading={saving}/>
                </div>
            </div>
        </div>
    </>
}