import {Dropdown, Icon, Input, Label, Loader, Modal} from "semantic-ui-react";
import React, {SyntheticEvent} from "react";
import {useToasts} from "react-toast-notifications";

export interface MyConfirmAction {
    open: boolean
    cancelButton: string
    confirmButton: string
    content: string
    onConfirm?: (event?: SyntheticEvent, data?: object) => void
    onCancel?: (event?: SyntheticEvent, data?: object) => void
}

export const my_initial_confirm: MyConfirmAction = {cancelButton: "Close", confirmButton: "Proceed", content: "", open: false}

export interface Fetch {
    start: number
    limit: number
    total: number
    page: number
    pages: number
    load?: boolean
}

export class InputFile {
    file_src: string;
    file: File | undefined;

    constructor(file_path: string, file?: File) {
        this.file_src = file_path;
        this.file = file
    }
}


export function Loading(props: { show: boolean, text: string, hide: () => void }) {
    return (
        <Modal size='small' basic closeOnEscape={false} closeOnDimmerClick={false}
               open={props.show} onClose={props.hide}>
            <Loader active inline='centered'>{props.text}</Loader>
        </Modal>)
}

export function MyInput(
    props: {
        change?: (name: string, value: string) => void, placeholder: string, name: string, value: string,
        disabled?: boolean, style?: React.CSSProperties, type?: string, enter?: () => void
    }) {
    return (
        <>
            <Input placeholder={props.placeholder} fluid size="mini" name={props.name} value={props.value}
                   disabled={props.disabled !== undefined && props.disabled}
                   style={props.style} type={props.type}

                   onChange={((event, data) => {
                       if (props.change !== undefined) {
                           props.change(event.target.name, data.value)
                       }

                   })}
                   onKeyDown={(event: KeyboardEvent) => {
                       if (event.key === 'Enter' && props.enter !== undefined) {
                           props.enter()
                       }
                   }}

            />
        </>
    )
}

export type select_type = string | number | boolean | (string | number | boolean)[] | undefined


export function MySelect(
    props: {
        change?: (value: select_type) => void, name: string, value: select_type, placeholder: string, disabled?: boolean,
        options: Array<{ text: string, value: string | number }>, style?: React.CSSProperties, multiple?: boolean
    }) {
    return (
        <>
            <Dropdown size="mini" fluid selection placeholder={props.placeholder} value={props.value}
                      options={props.options} style={props.style} search={true} multiple={props.multiple ? props.multiple : false}
                      disabled={props.disabled ? props.disabled : false}
                      onChange={((event, data) => {
                          if (props.change) {
                              props.change(data.value)
                          }
                      })}/>
        </>
    )
}

export function MyDateInput(
    props: {
        minDate?: string, maxDate?: string, value: string, name: string, placeholder: string,
        change: (name: string, value: string) => void
    }
) {
    return (
        <>
            <input className="date_input" name={props.name} placeholder={props.placeholder} value={props.value}
                   type="date" max={props.maxDate} min={props.minDate}
                   onChange={(event) => props.change(event.target.name, event.target.value)}/>
        </>
    )
}

export interface MessageProps {
    type: 'success' | 'error' | 'info'
    active: boolean
    message: string
}

export function MyMessage(props: { message: string, type: 'success' | 'error' | 'info', close: () => void, active: boolean }) {
    const [color, setColor] = React.useState<'blue' | 'red' | 'green'>('blue')
    const [icon, setIcon] = React.useState<'checkmark' | 'info' | 'cancel'>('info')

    const get_color = () => {
        if (props.type === 'success') {
            return 'green'
        } else if (props.type === 'error') {
            return 'red'
        }
        return 'blue'
    }

    const get_icon = () => {
        if (props.type === 'success') {
            return 'checkmark'
        } else if (props.type === 'error') {
            return 'cancel'
        }
        return 'info'
    }

    React.useEffect(() => {
        setColor(get_color)
        setIcon(get_icon)
    }, [])

    React.useEffect(() => {
        setColor(get_color)
        setIcon(get_icon)
    }, [props.type])

    return (
        <Modal size='mini' open={props.active} onClose={props.close} dimmer="blurring" centered={false}>
            <div className="dialog_container">
                <div className="dialog_icon">
                    <Icon name={icon} color={color} size='large'/>
                </div>
                <div className="dialog_message">
                    {props.message}
                </div>
            </div>
        </Modal>
    )
}

export const initial_toast: { type: 'info' | 'success' | 'error', message: string, time: number } =
    {type: 'info', message: "", time: Date.now()}

export function Toaster(params: { type: 'info' | 'success' | 'error', message: string, time: number }) {
    const {addToast} = useToasts()

    React.useEffect(() => {
        if (params.message !== "") {
            switch (params.type) {
                case "error":
                    addToast(params.message, {appearance: 'error', autoDismiss: true})
                    break;
                case "success":
                    addToast(params.message, {appearance: 'success', autoDismiss: true})
                    break;
                case "info":
                    addToast(params.message, {appearance: 'info', autoDismiss: true})
                    break;
            }
        }
    }, [params.time, params.message, params.type])
    return (
        <></>
    )
}

export default class Utils {
    static today = (initial?: Date) => {
        if (initial === undefined) {
            initial = new Date()
        }
        const year = initial.getFullYear()
        let month = initial.getMonth() + 1
        const date = initial.getDate()
        return year + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date)
    }

    static post_data(operation: string, data: any) {
        const form = new FormData();
        form.append("operation", operation);
        Object.entries(data).forEach(([prop, val]) => {
            if (val !== null) {
                if (val instanceof File) {
                    form.append(prop, val as File)
                } else {
                    form.append(prop, val as string)
                }
            }
        })
        return form
    }

    static get_data(data: any) {
        const form = new FormData();
        Object.entries(data).forEach(([prop, val]) => {
            if (val !== null) {
                if (val instanceof InputFile) {
                    if (val.file !== undefined) {
                        form.append(prop, val.file as File)
                    }
                } else {
                    form.append(prop, val as string)
                }
            }
        })
        return form
    }

    public static in_valid_email(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !regex.test(email);
    }

    public static invalid_name(name: string): boolean {
        //const regex = /^[a-zA-Z]+(?:-[a-zA-Z]+)*$/;
        const regex = /^([a-zA-Z ]{2,100})$/;
        //const regex = /^[a-zA-Z]+(?:(?:\. |[' ])[a-zA-Z]+)*/;
        return !regex.test(name);
    }

    public static invalid_user_name(name: string): boolean {
        const regex = /^[a-zA-Z]{2,30}$/;
        return !regex.test(name);
    }

    public static invalid_text(name: string): boolean {
        //const regex = /^[a-zA-Z0-9 ]{2,500}$/;
        //return !regex.test(name);
        return name.length < 5
    }

    public static invalid_contact(contact: string): boolean {
        return contact.replace(/\D/g, '').length < 12
    }

    public static localise_date(string: string): string {
        const date = new Date(string.replace(/-/g, "/"))
        return date.toLocaleDateString(undefined,
            {weekday: 'short', year: 'numeric', month: 'short', day: '2-digit'})
    }

    public static is_valid_number(value: string): boolean {
        return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
    }

    public static comma_number(value: number, pre?: string, post?: string): string {
        const prefix = pre === undefined ? 'shs ' : pre
        const postfix = post === undefined ? '' : post
        return prefix + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + postfix
    }

    static row_number(index: number, fetch?: Fetch) {
        let number = (index + 1)
        if (fetch !== undefined) {
            number = (index + 1) + (fetch.limit * (fetch.page - 1))
        }

        if (index === 0) {
            return <Label ribbon> {number} </Label>
        } else {
            return number
        }
    }
}