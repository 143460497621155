import React from "react";
import {Button, Confirm, Modal} from "semantic-ui-react";
import moment from "moment";
import {Child, initial_child} from "./MyFamily";
import {Request} from "../../../utils/Request";
import Utils, {Loading, MessageProps, my_initial_confirm, MyConfirmAction, MyDateInput, MyInput, MyMessage, MySelect} from "../../../utils/Utils";
import {countries} from "../../../utils/Countries";

export default function AddMember(props: { parent_id: number, child: Child, show: boolean, close: (child?: Child) => void }) {
    const [loader, setLoader] = React.useState({show: false, message: "Uploading data, please wait"})
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [child, setChild] = React.useState<Child>(props.child)
    const handle_client = (name: string, value: string | number) => {
        setChild({...child, [name]: value})
    }

    const save_child = () => {
        if (Utils.invalid_name(child.first_name.trim())) {
            setMessage({active: true, message: "Enter a valid first name", type: 'error'})
        } else if (Utils.invalid_name(child.last_name.trim())) {
            setMessage({active: true, message: "Enter a valid last name", type: 'error'})
        } else if (child.date_of_birth === "") {
            setMessage({active: true, message: "Enter a valid date of birth", type: 'error'})
        } else if (moment(child.date_of_birth).isBefore(moment("1900-01-01"))) {
            setMessage({active: true, message: "Date of birth cannot be before 01/01/1900", type: 'error'})
        } else if (moment(child.date_of_birth).isAfter(moment(Utils.today()))) {
            setMessage({active: true, message: "Date of birth cannot be after today", type: 'error'})
        } else if (child.client_gender === "") {
            setMessage({active: true, message: "Please select a gender", type: 'error'})
        } else if (child.nationality === "") {
            setMessage({active: true, message: "Please select nationality", type: 'error'})
        } else {
            setConfirm({
                ...confirm,
                'open': true,
                'content': 'Do you want to save child data?',
                'onConfirm': () => {
                    setConfirm({...confirm, 'open': false})

                    setLoader({message: "Saving child information, please wait", show: true})
                    Request.save_child_data({
                        client_gender: child.client_gender, client_id: child.client_id, date_of_birth: child.date_of_birth,
                        first_name: child.first_name.trim(), group_id: child.group_id, last_name: child.last_name,
                        nationality: child.nationality, other_name: child.other_name.trim(), parent_id: props.parent_id,
                        passport_no: child.passport_no.trim(),
                    })
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                                setMessage({active: true, type: 'success', message: "Successfully saved child information"})
                                props.close({...child, client_id: response.data.data.client_id})
                            } else {
                                setMessage({active: true, type: 'error', message: "Error while saving client data, please retry"})
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                        })
                },
                'onCancel': () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    React.useEffect(() => {
        setChild(props.child)
    }, [props.child])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content} header="Clear Data"/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={props.show} centered={false} onClose={() => props.close()}>
                <div className="family_modal">
                    <div className="family_form">
                        <div className="user_data_container">
                            <div className="label">First Name</div>
                            <MyInput placeholder="First Name" name="first_name"
                                     value={child.first_name} change={handle_client}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Last Name</div>
                            <MyInput placeholder="Last Name" name="last_name"
                                     value={child.last_name} change={handle_client}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Other Name (optional)</div>
                            <MyInput placeholder="Other Name" name="other_name"
                                     value={child.other_name} change={handle_client}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Nationality</div>
                            <MySelect
                                change={(value) => handle_client("nationality", value as string)}
                                name="nationality" value={child.nationality} placeholder="Select nationality"
                                options={countries}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Date of Birth</div>
                            <MyDateInput
                                value={child.date_of_birth} name="date_of_birth" maxDate={Utils.today()}
                                placeholder="Select date of birth" change={handle_client}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Gender</div>
                            <MySelect
                                change={(value) => handle_client("client_gender", value as string)}
                                name="client_gender" value={child.client_gender} placeholder="Select user gender"
                                options={[
                                    {value: "Other", text: "Other"},
                                    {value: "Male", text: "Male"},
                                    {value: "Female", text: "Female"},
                                ]}/>
                        </div>

                        <div className="">
                            <div className="label">Passport No. (necessary for travellers)</div>
                            <MyInput placeholder="Passport No" name="passport_no"
                                     value={child.passport_no} change={handle_client}/>
                        </div>
                    </div>

                    <div className="user_button bg_white">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative icon="close" labelPosition="left" size="mini" fluid
                                        content="Close Window" onClick={() => props.close()}/>
                            </div>

                            <div className="col-6 p-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid
                                        content="Save Data" onClick={save_child}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}