import axios from 'axios'
import Utils from "./Utils";

export class Request {
    static BASE_URL = "https://dev.coronatest.ug/"

    /*client requests*/
    static create_account(data: { email_address: string }) {
        return axios({method: 'post', url: 'auth/client/initialise', data: Utils.get_data(data)});
    }

    static verify_client_account(data: { verification_code: string, email_address: string, user_password: string }) {
        return axios({method: 'post', url: 'auth/client/verify', data: Utils.get_data(data)});
    }

    static login_client(data: { credential: string, login_password: string }) {
        return axios({method: 'post', url: 'auth/client/login', data: Utils.get_data(data)});
    }

    static update_account(data: { data: string }) {
        return axios({method: 'post', url: 'client/update', data: Utils.get_data(data)});
    }

    static save_child_data(data: {
        client_id: number, group_id: number, first_name: string, last_name: string, other_name: string,
        client_gender: string, date_of_birth: string, passport_no: string, nationality: string, parent_id: number
    }) {
        return axios({method: 'post', url: 'client/child/save', data: Utils.get_data(data)});
    }

    static get_parent_children(params: { client_id: number }) {
        return axios({method: 'get', url: 'client/children', params: params});
    }

    static initialise_booking(params: { client_id: number }) {
        return axios({method: 'get', url: 'client/booking/init', params: params});
    }

    static book_test(data: {
        client_id: number, tests_booked: number, amount_paid: number,
        booking_date: string, start_time: string, end_time: string
    }) {
        return axios({method: 'post', url: 'booking/create', data: Utils.get_data(data)});
    }
}

axios.defaults.baseURL = Request.BASE_URL;
axios.defaults.headers['common']['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 1000 * 60

