import React from "react";
import {Button, Modal} from "semantic-ui-react";
import web_logo from "../../../assets/images/logo.jpg";
import {Request} from "../../utils/Request";
import Register from "./Register";
import {ClientInfo} from "../client/ClientAccount";

export default function Login(props: { show: boolean, close: (token: string, userData: ClientInfo) => void, message: string }) {
    const [clientLogin, setClientLogin] = React.useState(false)

    const [error, setError] = React.useState("")
    const [credential, setLoginData] = React.useState({email: "", password: ""})

    const login_client = () => {
        setClientLogin(true)
        localStorage.clear()

        Request.login_client({credential: credential.email, login_password: credential.password})
            .then((response) => {
                setClientLogin(false)
                console.log(response)
                if (response.data.hasOwnProperty("code")) {
                    const code: number = response.data.code
                    if (code === 1) {
                        props.close(response.data.data.login_token, response.data.data.data)
                    } else if (code === 2) {
                        setError("Please make sure you have provided email or contact and password")
                    } else if (code === 3) {
                        setError("Invalid credentials, please check your credentials and retry")
                    } else if (code === 4) {
                        setError("You have not verified your account yet, follow linkin your email to verify")
                    }
                } else {
                    setError("Could not login, please retry")
                }
            })
            .catch(() => {
                setClientLogin(false)
                setError("Could not login, please retry")
            })
    }

    const [register, setRegister] = React.useState(false)
    return (
        <>
            <Register show={register} close={() => setRegister(false)}/>

            <Modal size='mini' open={props.show} centered={false} closeOnDimmerClick={false} closeOnEscape={false}>
                <div className="login_window">
                    <div className="ui large form">
                        <div className="ui stacked secondary segment">
                            <div className="login_logo">
                                <img src={web_logo} alt=""/>
                            </div>

                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="user icon"/>
                                    <input type="text" placeholder="Email address" value={credential.email}
                                           onChange={(event) => {
                                               setLoginData({...credential, 'email': event.target.value})
                                           }}/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="lock icon"/>
                                    <input type="password" placeholder="Login password" value={credential.password}
                                           onChange={(event) => {
                                               setLoginData({...credential, 'password': event.target.value})
                                           }}
                                           onKeyDown={(event: any) => {
                                               if (event.keyCode === 13) {
                                                   login_client()
                                               }
                                           }}
                                    />
                                </div>
                            </div>

                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <Button className="ui button fluid primary" size='small' loading={clientLogin}
                                            disabled={clientLogin} onClick={login_client} content="Proceed Account Login"/>
                                </div>
                            </div>

                            <div className="row mx-0 mt-2">
                                <div className="col-12 p-1">
                                    <span className="login_message" onClick={() => setRegister(true)}>
                                        Have no account? Create Account
                                    </span>
                                </div>
                                <div className="col-12 p-0">
                                    <span className="login_message" onClick={() => setRegister(true)}>
                                        Forgot password? Reset Password
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}