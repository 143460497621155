import React from "react";
import axios from 'axios'
import web_logo from '../../assets/images/logo.jpg'
import {MessageProps, MyMessage} from "../utils/Utils";
import Login from "./account/Login";
import ClientAccount, {ClientInfo, initial_client} from "./client/ClientAccount";
import Booking from "./client/Booking";
import MyFamily from "./client/family/MyFamily";

export default function AppBar() {
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [userToken, setUserToken] = React.useState("")
    const [login, setLogin] = React.useState({show: false, message: ""})

    const [clientInfo, setClientInfo] = React.useState<ClientInfo>(initial_client)
    const [page, setPage] = React.useState("")

    const set_axios_data = () => {
        axios.interceptors.response.use(function (response) {
            console.log(response.data)
            console.log(response)

            if (response.data.hasOwnProperty("code") && response.data.code === 401) {
                setLogin({show: true, message: "Account was logged in from another session, your login is required"})
            }
            if (response.data.hasOwnProperty("data") && response.data.data.hasOwnProperty("client_token")) {
                setUserToken(response.data.data.client_token)
            }
            return response;
        }, function (error) {
            console.log(error)
            return Promise.reject(error);
        });
    }

    React.useEffect(() => {
        axios.defaults.headers['common'] = {'Authorization': `bearer ${userToken}`}
        if (userToken === "") {
            setLogin({message: "", show: true})
        }
    }, [userToken])

    React.useEffect(() => {
        set_axios_data()
    }, [])

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Login show={login.show} message={login.message}
                   close={(token, userData) => {
                       setClientInfo(userData)
                       setUserToken(token)
                       setLogin({message: "", show: false})
                       setPage("account")
                   }}/>

            {
                clientInfo.client_id > 0 &&
                <>
                    <div className='nav_bar'>
                 <span className={`header ${page === 'account' && 'active'}`} onClick={() => setPage("account")}>
                    <i className="fas fa-user"/>
                    <span className="sub_title">My Account</span>
                </span>

                        {
                            clientInfo.client_id > 0 && clientInfo.is_child === 0 &&
                            <span className={`header ${page === 'family' && 'active'}`} onClick={() => setPage("family")}>
                        <i className="fas fa-user-friends"/>
                        <span className="sub_title">My Family</span>
                    </span>
                        }

                        <span className={`header ${page === 'booking' && 'active'}`} onClick={() => setPage("booking")}>
                    <i className="fas fa-calendar-check"/>
                    <span className="sub_title">Book Test</span>
                </span>

                        <span className="header logout"
                              onClick={() => {
                                  setClientInfo(initial_client)
                                  setUserToken("")
                                  setLogin({message: "", show: true})
                                  setPage("")
                              }}>
                   <span> <i className="fas fa-power-off"/></span>
                    <span className="sub_title">
                        {clientInfo.first_name + " " + clientInfo.last_name + " " + clientInfo.other_name}
                    </span>
                </span>
                    </div>

                    <div className="nav_content">
                        {page === 'account' && <ClientAccount client_info={clientInfo} setClientInfo={setClientInfo}/>}
                        {page === 'booking' && <Booking client_info={clientInfo}/>}
                        {page === 'family' && <MyFamily client_info={clientInfo}/>}
                    </div>

                    <div className='footer'>
                        Copyright Medipal International Hospital, Kampala, Uganda, 2021
                    </div>
                </>
            }
        </>
    )
}