import React from "react";
import {Button, Modal} from "semantic-ui-react";
import web_logo from "../../../assets/images/logo.jpg";
import Utils, {MessageProps, MyMessage} from "../../utils/Utils";
import {Request} from "../../utils/Request";

declare var grecaptcha: any;

export default function Register(props: { show: boolean, close: () => void }) {
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const [credential, setLoginData] = React.useState({email: ""})
    const [recaptcha, setRecaptcha] = React.useState("")

    const login_user = () => {
        if (grecaptcha.getResponse() === "") {
            setError("Please verify that you are not a robot")
        } else if (Utils.in_valid_email(credential.email.trim())) {
            setError("Enter a valid email address")
        } else {
            setError("")
            setLoading(true)

            Request.create_account({email_address: credential.email.trim()})
                .then((response) => {
                    setLoading(false)
                    console.log(response)
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setMessage({
                            active: true, type: "success",
                            message: "Your account has been successfully created, " +
                                "please follow link in email to activate your account"
                        })
                        window.setTimeout(() => props.close(), 5000)
                    } else {
                        setError("Error while creating account, please retry")
                    }
                })
                .catch(() => {
                    setLoading(false)
                    setError("Error while creating account, please retry")
                })
        }
    }

    React.useEffect(() => {
        if (props.show) {
            grecaptcha.render(document.getElementById("recaptcha"), {
                'sitekey': '6LcwIM8ZAAAAAICPMImt3vELicrHUYFWvmEIw-mE',
                'callback': function (response: any) {
                    setRecaptcha(response)
                }
            });

            grecaptcha.reset(
                setRecaptcha("")
            )
        }
        setRecaptcha("")
    }, [props.show])

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={props.show} centered={false}
                   closeOnDimmerClick={false} closeOnEscape={false} onClose={props.close}>
                <div className="login_window">
                    <div className="ui large form">
                        <div className="ui stacked secondary segment">
                            <div className="login_logo">
                                <img src={web_logo} alt=""/>
                            </div>

                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="user icon"/>
                                    <input type="text" placeholder="Email address" value={credential.email}
                                           onChange={(event) => {
                                               setLoginData({...credential, 'email': event.target.value})
                                           }}/>
                                </div>
                            </div>
                            <div id="recaptcha"/>

                            <Button className="ui button fluid primary mt-2" size='small' loading={loading} disabled={loading}
                                    onClick={() => login_user()}>
                                Send Email Verification Link
                            </Button>

                            <div className="mt-2">
                                <span className="login_message" onClick={props.close}>Already have an account? Login</span>
                            </div>
                        </div>
                        <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}