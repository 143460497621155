import React from "react";
import {Table} from "semantic-ui-react";
import AddMember from "./AddMember";
import {Request} from "../../../utils/Request";
import Utils, {Loading, MessageProps, MyMessage} from "../../../utils/Utils";
import {ClientInfo} from "../ClientAccount";

export interface Child {
    client_id: number
    group_id: number
    first_name: string,
    last_name: string,
    other_name: string,
    client_gender: string,
    date_of_birth: string
    passport_no: string
    nationality: string
}

export const initial_child: Child = {
    client_gender: "", client_id: 0, date_of_birth: "", first_name: "", group_id: 0,
    last_name: "", nationality: "", other_name: "", passport_no: ""
}

export default function MyFamily(params: { client_info: ClientInfo }) {
    const [loader, setLoader] = React.useState({show: false, message: "Uploading data, please wait"})
    const [children, setChildren] = React.useState<Array<Child>>([])
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [modal, setModal] = React.useState({show: false, child: initial_child})

    const get_family_members = () => {
        setLoader({message: "Loading child data, please wait", show: true})
        Request.get_parent_children({client_id: params.client_info.client_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setChildren(response.data.data)
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
            })
    }

    React.useEffect(() => {
        get_family_members()
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <AddMember parent_id={params.client_info.client_id} child={modal.child} show={modal.show}
                       close={(child) => {
                           if (child !== undefined) {
                               const total = children.length
                               let _children = children.map((_child: Child) => _child === child ? _child : child)
                               if (_children.length === total) {
                                   _children = [child, ..._children]
                               }
                               setChildren(_children)
                           }
                           setModal({child: initial_child, show: false})
                       }}
            />

            <div className='family_container'>
                <div className="table_container p-0">
                    <Table celled striped compact size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '200px'}}>Child Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Gender</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Date of Birth</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Nationality</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Passport No.</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                children.map((child, index) =>
                                    <Table.Row
                                        key={child.client_id} onClick={() => setModal({child: child, show: true})}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">
                                            {Utils.row_number(index)}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '200px'}}>
                                            {`${child.last_name} ${child.first_name} ${child.other_name}`}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{child.client_gender}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{Utils.localise_date(child.date_of_birth)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{child.nationality}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{child.passport_no}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>

            <div className='family_link' onClick={() => setModal({child: initial_child, show: true})}>
                <i className="fas fa-plus-circle"/>
            </div>
        </>
    )
}