import React from 'react';
import {Route, Switch} from 'react-router'
import AppBar from "./system/AppBar";
import Certificate from "./system/Certificate";
import VerifyClient from "./system/account/VerifyClient";

export default function App() {
    return (
        <Switch>
            <Route exact path={Path.route_home} component={AppBar}/>
            <Route exact path={Path.route_certificate} component={Certificate}/>
            <Route exact path={Path.route_verify} component={VerifyClient}/>
            <Route component={AppBar}/>
        </Switch>
    )
};

export class Path {
    static route_home = "/"
    static route_certificate = "/certificate/:status/:start_index/:qr_code"
    static route_verify = "/verify/:user_token/:email_address"
}


