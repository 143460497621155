import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import get_store from "./store/store";
import {AppContainer} from "react-hot-loader";
import {Provider} from 'react-redux'
import {ConnectedRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {PersistGate} from "redux-persist/integration/react";

import 'react-phone-input-2/lib/style.css';
import 'semantic-ui-css/semantic.min.css'
import './assets/css/bootstrap-grid.css';
import './assets/css/index.css';
import {ToastProvider} from "react-toast-notifications";


const render = () => {
    let store_data = get_store();

    ReactDOM.render(
        <AppContainer>
            <Provider store={store_data.store}>
                <PersistGate loading={null} persistor={store_data.persistor}>
                    <ConnectedRouter history={createBrowserHistory()}>
                        <ToastProvider>
                            <App/>
                        </ToastProvider>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        </AppContainer>,
        document.getElementById('root')
    )
};
render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
