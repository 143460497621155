import React from "react";
import {useParams} from "react-router";
import Utils from "../utils/Utils";

export default function Certificate() {
    // @ts-ignore
    let {status, start_index, qr_code} = useParams()

    const [countdown, setCountdown] = React.useState({timestamp: 10000, hours: "0hrs 0min 0sec"})

    React.useEffect(() => {
        const intervalToLevels = (interval: number) => {
            const levels = {scale: [24, 60, 60, 1], units: ['days ', 'hrs ', 'min ', 'sec ']};

            const cbFun = (d: any, c: any) => {
                let bb = d[1] % c[0], aa = (d[1] - bb) / c[0];
                aa = aa > 0 ? aa + c[1] : '';
                return [d[0] + aa, bb];
            };

            let reduce = levels.scale
                .map((d, i, a) => a.slice(i).reduce((d, c) => d * c))
                .map((d, i) => ([d, levels.units[i]]))
                .reduce(cbFun, ['', interval]);
            return reduce[0];
        };


        const intervalId = setInterval(() => {
            if (countdown.timestamp >= 0) {
                const timestamp = countdown.timestamp - 1
                const secondsToString = intervalToLevels(timestamp);
                setCountdown({timestamp: timestamp, hours: secondsToString.toString()})
            } else {
                setCountdown({timestamp: 0, hours: '0hrs 0min 0sec'})
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [countdown])

    React.useEffect(() => {
        if (status !== undefined && Utils.is_valid_number(status) && start_index !== undefined && Utils.is_valid_number(start_index)) {
            setCountdown({timestamp: parseInt(start_index as string), hours: "0hrs 0min 0sec"})
        }
    }, [])

    return (
        <>
            {
                (status === "1" && start_index !== undefined && Utils.is_valid_number(start_index)) ?
                    <div className="certificate_body">
                        <div className="content">
                            <img src="https://api.coronatest.ug/resources/logos/logo.png" alt=""/>
                            <div className="sorry_text">
                                Sorry, your results have not yet been processed. Your results shall be available within not more than:
                            </div>
                            <div className="time_remaining">{countdown.hours}</div>
                            <div className='click'>
                                Click <a href={`https://api.coronatest.ug/certificate/qr_code/${qr_code}`}>here</a>
                                &nbsp; to reload page and check if the certificate has been uploaded
                            </div>
                        </div>
                    </div> :
                    <div className="certificate_body">
                        <div className="content">
                            <img src="https://api.coronatest.ug/resources/logo.png" alt=""/>
                            <div className="no_result_text">
                                Sorry, no results have ben found to match your QR Code.
                                <br/>In case you copied the link, make sure that it is fully copied.
                                Do not hesitate to reach us for further inquiries
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
