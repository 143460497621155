import React from "react";
import {Step, Stepper} from 'react-form-stepper';
import {ClientInfo} from "./ClientAccount";
import Calendar from 'react-calendar';
import {Button, Checkbox, Table} from "semantic-ui-react";
import {Request} from "../../utils/Request";
import Utils, {initial_toast, Loading, MySelect, Toaster} from "../../utils/Utils";

interface Test {
    client_id: number
    client_name: string
    test_type: 'ordinary' | 'express'
    passport_number: string
    add: boolean
}

export default function Booking(params: { client_info: ClientInfo }) {
    const [toast, setToast] = React.useState(initial_toast)
    const [activeStep, setActiveStep] = React.useState(1)
    const [booking, setBooking] = React.useState<{ date: Date | null, time_index: number, amount: number, tests: number, confirm: boolean }>(
        {date: null, time_index: -1, amount: 0, tests: 0, confirm: false});
    const [times, setTimes] = React.useState<Array<{ start: string, end: string }>>([])
    const [loading, setLoading] = React.useState({show: false, message: ''})
    const [frame, setFrame] = React.useState("")

    const [tests, setTests] = React.useState<Array<Test>>([])
    const [amounts, setAmounts] = React.useState({ordinary: 0, express: 0})

    const proceed_booking = () => {
        if (booking.date === null) {
            setToast({message: "Select a booking date", time: Date.now(), type: 'error'})
        } else if (booking.time_index === -1) {
            setToast({message: "Select expected time period for booking", time: Date.now(), type: 'error'})
        } else {
            setLoading({message: "Initialising test booking, please wait", show: true})
            Request.book_test({
                amount_paid: booking.amount, booking_date: Utils.today(booking.date),
                client_id: params.client_info.client_id, end_time: times[booking.time_index].end,
                start_time: times[booking.time_index].start, tests_booked: booking.tests
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setFrame(response.data.data.frame)
                    } else {
                        setToast({
                            message: "Error while initialising booking, please retry", time: Date.now(), type: 'error'
                        })
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    setToast({
                        message: "Error while initialising booking, please retry", time: Date.now(), type: 'error'
                    })
                })
        }
    }

    React.useEffect(() => {
        const leading_zero = (item: number) => {
            return item < 10 ? "0" + item.toString() : item.toString()
        }
        const load_times = () => {
            let _times: Array<{ start: string, end: string }> = []
            for (let hour = 8; hour < 17; hour++) {
                for (let minute = 20; minute <= 60; minute += 20) {
                    const start_hour = hour
                    const start_minute = (minute - 19)
                    const end_hour = minute === 60 ? ((start_hour) + 1) : hour
                    const end_minute = minute === 60 ? 0 : minute

                    _times = [..._times, {
                        start: `${leading_zero(start_hour)}:${leading_zero(start_minute)}`,
                        end: `${leading_zero(end_hour)}:${leading_zero(end_minute)}`
                    }]
                }
            }
            setTimes(_times)
        }
        load_times()

        const initialise_booking = () => {
            setLoading({message: "Initialising data, please wait", show: true})
            Request.initialise_booking({client_id: params.client_info.client_id})
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setAmounts(response.data.data.costs)

                        let _tests: Array<Test> = [
                            {
                                client_id: params.client_info.client_id, test_type: 'ordinary', add: true,
                                passport_number: params.client_info.passport_no,
                                client_name: `${params.client_info.first_name} ${params.client_info.last_name} ${params.client_info.other_name}`,
                            }
                        ];
                        (response.data.data.children as Array<any>)
                            .forEach((child) => {
                                _tests = [..._tests,
                                    {
                                        client_id: child.client_id, test_type: 'ordinary', add: false,
                                        passport_number: child.passport_no,
                                        client_name: `${child.first_name} ${child.last_name} ${child.other_name}`,
                                    }
                                ]
                            })
                        setTests(_tests)
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                })
        }
        initialise_booking()
    }, [])

    React.useEffect(() => {
        let _amount = 0
        let _total = 0
        tests.forEach((test) => {
            if (test.add) {
                if (test.test_type === "ordinary") {
                    _amount += amounts.ordinary
                } else if (test.test_type === "express") {
                    _amount += amounts.express
                }
                _total += 1
            }
        })
        setBooking({...booking, amount: _amount, tests: _total})
    }, [tests])

    return (
        <>
            <Toaster type={toast.type} message={toast.message} time={toast.time}/>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Stepper activeStep={activeStep} nonLinear={false}>
                <Step label="Booking Time"/>
                <Step label="Booking Payment"/>
            </Stepper>

            <div className='booking_container'>
                {
                    activeStep === 1 &&
                    <div className='row m-0 h-100'>
                        <div className='col-12 col-md-6 p-2 h-100'>
                            <div className='booking_data'>
                                <Calendar
                                    onChange={(date) => setBooking({...booking, date: date as Date})}
                                    value={booking.date} minDate={new Date()}
                                    maxDate={new Date(((new Date()).getTime() + (14 * 24 * 60 * 60 * 1000)))}
                                />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 p-2'>
                            <div className='booking_data'>
                                <div className='row m-0'>
                                    {
                                        times.map((time, index) =>
                                            <div className='col-4 booking_time' key={index}
                                                 onClick={() => setBooking({...booking, time_index: index})}>
                                                <div className={`${booking.time_index === index && 'selected'}`}>
                                                    <span>{time.start}</span>
                                                    <span> to </span>
                                                    <span>{time.end}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    activeStep === 2 &&
                    <div className='row m-0 h-100'>
                        <div className='col-12 col-md-6 h-100 p-2'>
                            <div className='booking_data'>
                                <div className="table_container booking_users p-0">
                                    <Table celled striped compact size='small' inverted color='grey' selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{width: '65px'}} textAlign="center"/>
                                                <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '140px'}}>Passport No</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '140px'}}>Test Type</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                tests.map((test) =>
                                                    <Table.Row
                                                        key={test.client_id}>
                                                        <Table.Cell style={{width: '65px'}} textAlign="center">
                                                            <Checkbox
                                                                label='Test' checked={test.add}
                                                                onChange={(event, data) => {
                                                                    setTests(
                                                                        tests.map((_test) => _test.client_id === test.client_id ?
                                                                            {..._test, add: data.checked as boolean} : _test
                                                                        ))
                                                                }}/>
                                                        </Table.Cell>
                                                        <Table.Cell style={{width: '200px'}}>{test.client_name}</Table.Cell>
                                                        <Table.Cell style={{width: '140px'}}>{test.passport_number}</Table.Cell>
                                                        <Table.Cell style={{width: '140px'}}>
                                                            <MySelect
                                                                change={(value) => {
                                                                    setTests(
                                                                        tests.map((_test) => _test.client_id === test.client_id ?
                                                                            {..._test, test_type: value as 'express' | 'ordinary'} : _test
                                                                        ))
                                                                }}
                                                                name={`test_type_${test.client_id}`} value={test.test_type}
                                                                placeholder="Select test type"
                                                                options={[
                                                                    {value: "ordinary", text: "Ordinary Test"},
                                                                    {value: "express", text: "Express Test"},
                                                                ]}/>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>

                                <div className='row m-0 note'>
                                    <div className='col-12 col-md-6 p-1'>
                                        <div className='header'>Test Pricing</div>
                                        <span className='header'>Ordinary Test</span>
                                        <span className='cost'>{Utils.comma_number(amounts.ordinary, 'UGX ')}</span>
                                        <span className='header'>Express Test</span>
                                        <span className='cost'>{Utils.comma_number(amounts.express, 'UGX ')}</span>
                                    </div>
                                    <div className='col-12 col-md-6 p-1'>
                                        <span className='total'>{Utils.comma_number(booking.amount, 'UGX ')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-md-6 h-100 p-2'>
                            <div className='booking_data'>
                                {
                                    frame === "" ?
                                        <div className='user_consent'>
                                            <ul>
                                                <li>
                                                    You understand that, although we do our best, you are responsible for complying with
                                                    the laws and regulations of the country.
                                                </li>
                                                <li>We cannot be held responsible for regulatory changes.</li>
                                                <li> You are also aware that we do not backdate results.</li>
                                                <li>
                                                    You also understand that if you book an appointment with us just before departure
                                                    (for example a few days), there is a chance that no retest can be done if this should prove
                                                    necessary.
                                                </li>
                                                <li>
                                                    You also understand that in case of calamities (for example fire, power failure, etc.) there may
                                                    not be time for a new test (serology and / or PCR).
                                                </li>
                                                <li>
                                                    Because the processing of the tests also takes time, we strongly advise you not to make an
                                                    appointment too close to your departure.
                                                </li>
                                                <li>
                                                    We are not responsible for the consequences of not being able to (re) test in case of calamities.
                                                    You can confirm our schedule condition; read it carefully.
                                                </li>
                                            </ul>

                                            <Checkbox
                                                label='I hereby declare that the information provided is true and correct.'
                                                checked={booking.confirm} className='mt-3'
                                                onChange={(event, data) => {
                                                    setBooking({...booking, confirm: data.checked as boolean})
                                                }}/>
                                        </div> :
                                        <div dangerouslySetInnerHTML={{__html: frame}} className='h-100'/>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className='booking_previous'>
                <Button icon='angle double left' basic disabled={activeStep === 1}
                        onClick={() => setActiveStep(1)}/>
            </div>

            <div className='booking_next'>
                <Button icon='angle double right' basic
                        onClick={() => {
                            if (activeStep === 1) {
                                setActiveStep(2)
                            } else {
                                proceed_booking()
                            }
                        }}
                        disabled={(activeStep === 2 && (booking.date === null || booking.time_index === -1
                            || !booking.confirm || booking.amount === 0))}/>
            </div>
        </>
    )
}