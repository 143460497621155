import React from "react";
import {Button, Modal} from "semantic-ui-react";
import web_logo from "../../../assets/images/logo.jpg";
import {Request} from "../../utils/Request";
import {MessageProps, MyMessage} from "../../utils/Utils";
import {useParams} from "react-router";

export default function VerifyClient() {
    // @ts-ignore
    const {user_token, email_address} = useParams()

    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const [passwords, setPasswords] = React.useState({user_password: "", confirm_password: ""})

    const verify_account = () => {
        setLoading(true)
        Request.verify_client_account({
            email_address: email_address as string,
            user_password: passwords.confirm_password,
            verification_code: user_token as string
        })
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("code")) {
                    const code: number = response.data.code
                    if (code === 1) {
                        setMessage({active: true, type: "success", message: "Successfully verified account",})
                        window.setTimeout(() => window.location.replace("/"), 3000)
                    } else if (code === 2) {
                        setError("Your account could not be found, please make sure that you coped the full link url")
                    }
                } else {
                    setError("We could not verify your account, please retry")
                }
            })
            .catch(() => {
                setLoading(false)
                setError("We could not verify your account, please retry")
            })
    }

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={true} centered={true} closeOnDimmerClick={false} closeOnEscape={false}
                   closeOnDocumentClick={false} closeOnTriggerBlur={false}>
                <div className="login_window">
                    <div className="ui large form">
                        <div className="ui stacked secondary segment">
                            <div className="login_logo">
                                <img src={web_logo} alt=""/>
                            </div>

                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="lock icon"/>
                                    <input type="password" placeholder="Enter new password" value={passwords.user_password}
                                           onChange={(event) => {
                                               setPasswords({...passwords, user_password: event.target.value})
                                           }}/>
                                </div>
                            </div>

                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="lock icon"/>
                                    <input type="password" placeholder="Confirm new password" value={passwords.confirm_password}
                                           onChange={(event) => {
                                               setPasswords({...passwords, confirm_password: event.target.value})
                                           }}/>
                                </div>
                            </div>

                            <div className="row m-0">
                                <Button className="ui button fluid primary" size='small' loading={loading} disabled={loading}
                                        onClick={() => verify_account()}>
                                    Update Account Password
                                </Button>
                            </div>
                        </div>
                        <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}